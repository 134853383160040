import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Banner } from '../acf/Banner';
import PostList from '../components/PostList';
import { Pagination } from '../components/Pagination';
import './category.scss';

const Category = props => {
  const { data, pageContext, location } = props
  const { allWordpressPost: posts, siteSettings } = data;
  const { options } = siteSettings;
  const { blogBannerImage = null } = options;
  const { totalCount } = posts;
  const { title: siteTitle, wordpressUrl, blogSlug } = data.site.siteMetadata
  const { name: categoryName, slug: categorySlug, numberOfPages, pageNumber: currentPage } = pageContext;
  const title = `${totalCount} post${ totalCount === 1 ? '' : 's'} in the “${categoryName}” category`
  const breadcrumbs = [
    {
      pageLink: `/${blogSlug}/`,
      crumbText: 'Blog'
    },
    {
      crumbText: 'Category'
    }
  ];

  return (
    <Layout location={location} className="category-page-template">
      <SEO title={`${categoryName} | ${siteTitle}`} />
      <Banner
        image={blogBannerImage}
        title={categoryName}
        wordpressUrl={wordpressUrl}
        breadcrumbs={breadcrumbs}
      />
      <PostList categorySlug={categorySlug} posts={posts} title={title} blogSlug={blogSlug} />
      <Pagination numPages={numberOfPages} pathPrefix={`/${blogSlug}/category/${categorySlug}/`} currentPage={currentPage} />
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query CategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    siteSettings: wordpressAcfOptions {
      options {
        blogBannerImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        wordpressUrl
        blogSlug
      }
    }
    allWordpressPost(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          title
          slug
          excerpt
          date(formatString: "MMMM DD, YYYY")
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          categories {
            wordpress_id
            name
            slug
          }
        }
      }
    }
  }
`
